export type Phase = 'p1' | 'p2' | 'p3';
const timelineData = {
  start: '2025-04-03T18:00:00.000Z',
  p1: '2025-04-03T18:51:53.000Z',
  p2: '2025-04-03T18:57:22.000Z',
  p3: '2025-04-03T18:59:10.000Z',
  end: '2025-04-03T19:30:00.000Z',
};
const dictionaryShort = {
  p1: 'Start przelotu',
  p2: 'Max przelotu',
  p3: 'Cień Ziemi',
};

const dictionaryLong = {
  p1: 'początek przelotu',
  p2: 'maksimum przelotu',
  p3: 'wejście w cień Ziemi',
};

const PHASES: Phase[] = ['p1', 'p2', 'p3'];

export {
  timelineData, dictionaryShort, dictionaryLong, PHASES,
};
